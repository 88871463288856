import {SDKProvider, useSDK} from '@tma.js/sdk-react';
import {Alert, Backdrop, CircularProgress, CssBaseline} from "@mui/material";
import StartParamParser from "./containers/base/StartParamParser";
import {setLoading} from "./store/slices/navSlice";
import {useDispatch, useSelector} from "react-redux";
import ErrorBoundary from "./hoc/ErrorBoundary";
import sdkContext from "./telegramSdk/sdkContext";
import {BrowserRouter} from 'react-router-dom';
import React from "react";
import VersionChecker from "./containers/VersionChecker";

/**
 * This component is the layer controlling the application display. It displays
 * application in case, the SDK is initialized, displays an error if something
 * went wrong, and a loader if the SDK is warming up.
 */
function DisplayGate({children}) {
    const {didInit, components, error} = useSDK();
    const dispatch = useDispatch();

    let noSdk = false;
    // There were no calls of SDK's init function. It means, we did not
    // even try to do it.
    if (!didInit) {
        dispatch(setLoading(false));
        return null;
    }

    // Error occurred during SDK init.
    if (error !== null) {
        //dispatch(setError("Error occurred during SDK init."));
        dispatch(setLoading(false));
        noSdk = true;
        //return null;
    }

    // If components is null, it means, SDK is not ready at the
    // moment and currently initializing. Usually, it takes like
    // several milliseconds or something like that, but we should
    // have this check.

    if (components === null) {
        if (noSdk === false) {
            return null;
        }
    }

    const mockInitData = {
        user: {
            //id: '6708385737',
            //id: "5008997817",
            //id: "6315057145", // adedoyin
            id: "6599661094",
            //id: '7002052270',
            //id: "6649882237", // debbie
            //id: '5459687233', // andre busha
            //id: '6606651387', // andreas kuna sasol
            //id: 6916866970, // johanness
            //id: 5044525942,
            //id: "671000521", // gary
            //id: '6810857120',
            //id: "6541977859",
            //id: "1510553420",
            // id: '5763340391', //JAMES
            //id: '7068794872',
            //id: '2122018418', // charls
            //id: "6582378911", // bheki ver
            // id: '5459687233', // andre vdb
            //id: '5707080905', // tumelo
            // id: '7034096344', // nico
            //id: '7173454394',
            //id: '6845147617',
            firstName: "John",
            lastName: "Doe",
            // other user details
        },
        //startParam: "confirm-eyJpdiI6Imczb1RqbjQ2NjdKOUdzWklja1FXVnc9PSIsInZhbHVlIjoiVjlTS3RaRDlvdGIvSkV3VUxjWkpCK1N4TTdwZFpuZkl5dnU1Z2FPM25hTT0iLCJtYWMiOiJjYzQ5YWQwZjI1YTkzYTViMTBkNWFiNTJlMWU0MTM0ZmU3YTQ4MGZjOTRlZjRjYmZjNDY1MTViZGJjODZkM2NiIiwidGFnIjoiIn0%3D",
        //startParam: "divert-on_duty"
    };


    const initData = noSdk ? mockInitData : components['initData'];

    const context = {
        initData: initData,
        components: components,
        noSdk: noSdk,
    }
    // Safely render application.

    return <sdkContext.Provider value={context}>{children}</sdkContext.Provider>
}

function MyErrorBackdrop() {
    const lastError = useSelector((state) => {
        //console.log('Error:', state, state.nav, state.nav.error);
        return state.nav.error
    });

    return (
        lastError !== "" &&
        <Backdrop onClick={() => window.location.reload()}
                  sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                  open={true}
        >
            <Alert severity={"error"}>{lastError}</Alert>
        </Backdrop>

    )
}

function MyBackdrop() {
    const loading = useSelector((state) => state.nav.loading);
    console.log('Loading:', loading);
    return (
        loading ?
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                open={true}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            : null
    )
}

function Root() {
    //const miniApp = useMiniApp();

    //useEffect(() => {
    //    miniApp.ready();
    //}, []);
    console.log('rendering root');

    return (
        <ErrorBoundary fallback={<div>An error occurred</div>}>
            <MyErrorBackdrop/>
            <VersionChecker/>
            <SDKProvider initOptions={{debug: true, cssVars: true}}>
                <DisplayGate>
                    <BrowserRouter>
                        <CssBaseline/>
                        <StartParamParser/>
                    </BrowserRouter>
                </DisplayGate>
            </SDKProvider>
        </ErrorBoundary>
    );
}

export default Root;
