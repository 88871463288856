import {Collapse, IconButton, Table, TableBody, TableCell, TableRow} from "@mui/material";
import Grid from '@mui/material/Grid';
import {Fragment} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {styled} from "@mui/system";
import moment from "moment";

const TightGrid = styled(Grid)(({theme}) => ({
    '& > *': {
        padding: '2px 4px',
    },
}));

const TightTable = styled(Table)(({theme}) => ({
    '& > *': {
        padding: '2px 4px',
    },
}));

const TightTableCell = styled(TableCell)(({theme}) => ({
    padding: '2px 4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const TightTableRow = styled(TableRow)(({theme}) => ({
    '& > *': {
        padding: '2px 4px',
    },
}));

const CurrencyCell = styled(TightTableCell)(({theme}) => ({
    textAlign: 'right',
}));

const formatCurrency = (number, locale = 'en-US') => {
    const ret = parseFloat(number).toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return Number.isNaN(ret) ? 0 : ret;
};

const isArrayOfArrays = (array) => {
    return Array.isArray(array) && array.every(item => Array.isArray(item) || typeof item === 'object');
};

function InvoiceSharedBody({ data, handleToggle, open }) {
    return (
        <TightGrid container spacing={0}>

            <TightGrid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell>Driver</TightTableCell>
                            <TightTableCell>{data.header.driver}</TightTableCell>
                        </TightTableRow>
                        <TightTableRow>
                            <TightTableCell>Period</TightTableCell>
                            <TightTableCell>{data.header.period.start} -> {data.header.period.end}</TightTableCell>
                        </TightTableRow>
                        <TightTableRow>
                            <TightTableCell>Branch Codes</TightTableCell>
                            <TightTableCell>{data.header.branch_codes.join(', ')}</TightTableCell>
                        </TightTableRow>
                        <TightTableRow>
                            <TightTableCell>Days Shifted</TightTableCell>
                            <TightTableCell>{data.header.days_shifted}</TightTableCell>
                        </TightTableRow>
                        <TightTableRow>
                            <TightTableCell>Bank Account</TightTableCell>
                            <TightTableCell>{data.header.bank_details?.account_number}</TightTableCell>
                        </TightTableRow>
                        <TightTableRow>
                            <TightTableCell>Invoice Number</TightTableCell>
                            <TightTableCell>{data.header.invoice_number}</TightTableCell>
                        </TightTableRow>
                    </TableBody>

                </TightTable>
            </TightGrid>

            <Grid item xs={12} style={{height: '20px'}}></Grid>

            {Object.entries(data.income).map(([incomeKey, category]) => (
                Object.entries(category).length > 0 &&
                <Fragment key={`income-fragment-${incomeKey}`}>
                    <Grid item xs={12}>
                        <TightTable>
                            <TableBody>
                                <TightTableRow>
                                    <TightTableCell
                                        sx={{fontWeight: "bold"}}>{incomeKey.toUpperCase()}</TightTableCell>
                                    <TightTableCell>Qty</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Rate</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Amount</TightTableCell>
                                </TightTableRow>
                                {Object.entries(category).map(([entryKey, entry]) => {
                                    if (isArrayOfArrays(entry)) {
                                        let subTotal = 0;
                                        let count = 0;

                                        const rows = Object.entries(entry).map(([key, entry2]) => {
                                            subTotal += entry2.amount;
                                            count += entry2.cnt;
                                            return (
                                                <TightTableRow key={`${entryKey}-${key}`}>
                                                    <TightTableCell>{entry2.date?moment(entry2.date).format("ddd, DD MMM"):""}</TightTableCell>
                                                    <TightTableCell>{entry2.item} {entry2.description}</TightTableCell>
                                                    <TightTableCell>{entry2.hide_qty ? null : entry2.cnt}</TightTableCell>
                                                    <CurrencyCell>{entry2.hide_rate ? null : formatCurrency(entry2.rate)}</CurrencyCell>
                                                    <CurrencyCell>{formatCurrency(entry2.amount)}</CurrencyCell>
                                                </TightTableRow>
                                            );
                                        });

                                        const subTotalRow = (
                                            <TightTableRow key={`${entryKey}-sub`}>
                                                <TightTableCell
                                                    sx={{fontWeight: "bold"}}>
                                                    <IconButton onClick={() => handleToggle(entryKey)}>
                                                        {open[entryKey] ? <ExpandLess/> : <ExpandMore/>}
                                                    </IconButton>
                                                    Subtotal {entryKey}
                                                </TightTableCell>
                                                <TightTableCell sx={{fontWeight: "bold"}}>{count}</TightTableCell>
                                                <CurrencyCell></CurrencyCell>
                                                <CurrencyCell
                                                    sx={{fontWeight: "bold"}}>{formatCurrency(subTotal)}</CurrencyCell>
                                            </TightTableRow>
                                        );

                                        return (
                                            <Fragment key={`${entryKey}-fragment`}>
                                                {subTotalRow}
                                                <Collapse in={open[entryKey]} timeout="auto" unmountOnExit>
                                                    {rows}
                                                </Collapse>
                                            </Fragment>
                                        );
                                    } else {
                                        return <TightTableRow key={`income-entry-${entryKey}`}>
                                            <TightTableCell>{entry.item} {entry.description}</TightTableCell>
                                            <TightTableCell>{entry.hide_qty ? null : entry.cnt}</TightTableCell>
                                            <CurrencyCell>{entry.hide_rate ? null : formatCurrency(entry.rate)}</CurrencyCell>
                                            <CurrencyCell>{formatCurrency(entry.amount)}</CurrencyCell>
                                        </TightTableRow>
                                    }
                                })}
                                <TightTableRow>
                                    <TightTableCell
                                        sx={{fontWeight: "bold"}}>TOTAL {incomeKey.toUpperCase()}</TightTableCell>
                                    <TightTableCell></TightTableCell>
                                    <TightTableCell></TightTableCell>
                                    <CurrencyCell>{formatCurrency(data.totals[`income_${incomeKey}_total`])}</CurrencyCell>
                                </TightTableRow>
                            </TableBody>
                        </TightTable>
                    </Grid>

                    <Grid item xs={12} style={{height: '20px'}}></Grid>
                </Fragment>
            ))}

            <Grid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell sx={{fontWeight: "bold"}}>TOTAL CREDITS ({
                                Object.entries(data.income)
                                    .filter(([incomeKey, category]) => {
                                        return Array.isArray(category) ? category.length > 0 : Object.keys(category).length > 0;
                                    })
                                    .map(([incomeKey, category]) => incomeKey.toUpperCase()).join(' + ')
                            })</TightTableCell>
                            <TightTableCell></TightTableCell>
                            <CurrencyCell
                                sx={{fontWeight: "bold"}}>{formatCurrency(data.sub_totals[`income_total`])}</CurrencyCell>
                        </TightTableRow>
                    </TableBody>
                </TightTable>
            </Grid>

            <Grid item xs={12} style={{height: '20px'}}></Grid>

            {Object.entries(data.debits).map(([deductionKey, category]) => (
                Object.entries(category).length > 0 &&
                <Fragment key={`deduction-fragment-${deductionKey}`}>
                    <Grid item xs={12}>
                        <TightTable>
                            <TableBody>
                                <TightTableRow>
                                    <TightTableCell
                                        sx={{fontWeight: "bold"}}>{deductionKey.toUpperCase()} DEBITS</TightTableCell>
                                    <TightTableCell>Qty</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Rate</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Total</TightTableCell>
                                </TightTableRow>
                                {Object.entries(category).map(([entryKey, entry]) => {
                                    if (isArrayOfArrays(entry)) {
                                        let subTotal = 0;
                                        let count = 0;

                                        const rows = Object.entries(entry).map(([key, entry2]) => {
                                            subTotal += entry2.amount;
                                            count += entry2.cnt;
                                            return (
                                                <TightTableRow key={`${entryKey}-${key}`}>
                                                    <TightTableCell>{entry2.item} {entry2.description}</TightTableCell>
                                                    <TightTableCell>{entry2.hide_qty ? null : entry2.cnt}</TightTableCell>
                                                    <CurrencyCell>{entry2.hide_rate ? null : formatCurrency(entry2.rate)}</CurrencyCell>
                                                    <CurrencyCell>{formatCurrency(entry2.amount)}</CurrencyCell>
                                                </TightTableRow>
                                            );
                                        });

                                        const subTotalRow = (
                                            <TightTableRow key={`${entryKey}-sub`}>
                                                <TightTableCell
                                                    sx={{fontWeight: "bold"}}>
                                                    <IconButton onClick={() => handleToggle(entryKey)}>
                                                        {open[entryKey] ? <ExpandLess/> : <ExpandMore/>}
                                                    </IconButton>
                                                    Subtotal {entryKey}
                                                </TightTableCell>
                                                <TightTableCell sx={{fontWeight: "bold"}}>{count}</TightTableCell>
                                                <CurrencyCell></CurrencyCell>
                                                <CurrencyCell
                                                    sx={{fontWeight: "bold"}}>{formatCurrency(subTotal)}</CurrencyCell>
                                            </TightTableRow>
                                        );

                                        return (
                                            <Fragment key={`${entryKey}-fragment`}>
                                                {subTotalRow}
                                                <Collapse in={open[entryKey]} timeout="auto" unmountOnExit>
                                                    {rows}
                                                </Collapse>
                                            </Fragment>
                                        );
                                    } else {
                                        return <TightTableRow key={`deduction-entry-${entryKey}`}>
                                            <TightTableCell>{entry.item} {entry.description}</TightTableCell>
                                            <TightTableCell>{entry.hide_qty ? null : entry.cnt}</TightTableCell>
                                            <CurrencyCell>{entry.hide_rate ? null : formatCurrency(entry.rate)}</CurrencyCell>
                                            <CurrencyCell>{formatCurrency(entry.amount)}</CurrencyCell>
                                        </TightTableRow>
                                    }
                                })}
                                <TightTableRow>
                                    <TightTableCell
                                        sx={{fontWeight: "bold"}}>TOTAL {deductionKey.toUpperCase()} DEBITS</TightTableCell>
                                    <TightTableCell></TightTableCell>
                                    <TightTableCell></TightTableCell>
                                    <CurrencyCell>{formatCurrency(data.totals[`debits_${deductionKey}_total`])}</CurrencyCell>
                                </TightTableRow>
                            </TableBody>
                        </TightTable>
                    </Grid>

                    <Grid item xs={12} style={{height: '20px'}}></Grid>
                </Fragment>
            ))}

            <Grid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell sx={{fontWeight: "bold"}}>TOTAL DEBITS ({
                                Object.entries(data.debits)
                                    .filter(([deductionKey, category]) => {
                                        return Array.isArray(category) ? category.length > 0 : Object.keys(category).length > 0;
                                    })
                                    .map(([deductionKey, category]) => deductionKey.toUpperCase()).join(' + ')
                            })</TightTableCell>
                            <TightTableCell></TightTableCell>
                            <CurrencyCell
                                sx={{fontWeight: "bold"}}>{formatCurrency(data.sub_totals[`debits_total`])}</CurrencyCell>
                        </TightTableRow>
                    </TableBody>
                </TightTable>
            </Grid>

            <Grid item xs={12} style={{height: '20px'}}></Grid>

            <Grid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell sx={{fontWeight: "bold"}}>OPENING BALANCE</TightTableCell>
                            <TightTableCell></TightTableCell>
                            <CurrencyCell
                                sx={{fontWeight: "bold"}}>{formatCurrency(data.opening_balance)}</CurrencyCell>
                        </TightTableRow>
                    </TableBody>
                </TightTable>
            </Grid>
            <Grid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell sx={{fontWeight: "bold"}}>NETT Balance</TightTableCell>
                            <TightTableCell></TightTableCell>
                            <CurrencyCell
                                sx={{fontWeight: "bold"}}>{formatCurrency(data.invoice_total)}</CurrencyCell>
                        </TightTableRow>
                    </TableBody>
                </TightTable>
            </Grid>
            <Grid item xs={12}>
                <TightTable>
                    <TableBody>
                        <TightTableRow>
                            <TightTableCell sx={{fontWeight: "bold"}}>STATEMENT TOTAL</TightTableCell>
                            <TightTableCell></TightTableCell>l
                            <CurrencyCell
                                sx={{fontWeight: "bold"}}>{formatCurrency(data.eft_total)}</CurrencyCell>
                        </TightTableRow>
                    </TableBody>
                </TightTable>
            </Grid>

            <Grid item xs={12} style={{height: '20px'}}></Grid>

            {Object.entries(data.detail).map(([detailKey, category]) => (
                Object.entries(category).length > 0 &&
                <Fragment key={`detail-fragment-${detailKey}`}>
                    <Grid item xs={12}>
                        <TightTable>
                            <TableBody>
                                <TightTableRow>
                                    <TightTableCell
                                        sx={{fontWeight: "bold"}}>{detailKey.toUpperCase()}</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Short</TightTableCell>
                                    <TightTableCell sx={{textAlign: "right"}}>Fine</TightTableCell>
                                </TightTableRow>
                                {Object.entries(category).map(([entryKey, entry]) => (
                                    <TightTableRow key={`detail-entry-${entryKey}`}>
                                        <TightTableCell>{entry.date}</TightTableCell>
                                        <CurrencyCell>{formatCurrency(entry.short)}</CurrencyCell>
                                        <CurrencyCell>{formatCurrency(entry.fine)}</CurrencyCell>
                                    </TightTableRow>
                                ))}
                            </TableBody>
                        </TightTable>
                    </Grid>

                    <Grid item xs={12} style={{height: '20px'}}></Grid>
                </Fragment>
            ))}
        </TightGrid>
    );
}

export default InvoiceSharedBody;