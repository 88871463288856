import React, {useContext, useEffect, useState} from "react";
import Authenticator from "./pages/Authenticator";
import Layout from "./pages/Layout";
import Confirm from "./pages/Confirm";
import {useDispatch, useSelector} from "react-redux";
import {
    PAGE_AUTH,
    PAGE_CONFIRM,
    PAGE_LAYOUT,
    PAGE_LOADING,
    PAGE_ON_DUTY,
    setError,
    setPage
} from "../../store/slices/navSlice";
import sdkContext from "../../telegramSdk/sdkContext";
import {postEvent} from "../../telegramSdk/postEvent";
import OnDuty from "./pages/OnDuty";
import {MessageProvider} from "../../components/messageContext/MessageContext";

function StartParamParser() {
    //const initData = useInitData();
    const context = useContext(sdkContext);
    const [params, setParams] = useState([]);
    const page = useSelector((state) => state.nav.page);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    /*useEffect(() => {
        if( context.noSdk === false){
            const postEvent = context.components['postEvent'];
            postEvent('web_app_expand');
            postEvent('web_app_setup_closing_behavior', {need_confirmation: true});
        }
    }, [context]);*/

    useEffect(() => {
        postEvent(context, 'web_app_expand');
        postEvent(context, 'web_app_setup_closing_behavior', {need_confirmation: true});
    }, [context]);

    useEffect(() => {
        if (!context.initData.startParam || context.initData.startParam === '') {
            dispatch(setPage(PAGE_AUTH))
        } else {
            const slices = context.initData.startParam.split('-');

            if (slices.length < 2) {
                dispatch(setError("An error occurred initialising the App. E01"));
                return;
            }

            const params = slices.slice(1);
            const mode = slices[0];

            if (mode === 'divert') {
                dispatch(setPage(PAGE_AUTH));
                setParams(params[0]);
            } else if (mode === 'confirm') {
                dispatch(setPage(PAGE_CONFIRM));
                setParams(params);
            } else {
                dispatch(setPage(PAGE_AUTH))
            }

        }
    }, [dispatch, context.initData.startParam]);

    console.log('rendering start param parser', page, user);

    if (page === PAGE_LAYOUT) {
        return (
            <MessageProvider>
                <Layout></Layout>
            </MessageProvider>
        )
    }

    if (page === PAGE_CONFIRM) {
        return <Confirm initData={context.initData} params={params}/>;
    }

    if (page === PAGE_ON_DUTY) {
        return <OnDuty initData={context.initData} params={params}/>;
    }

    if (page === PAGE_LOADING) {
        return null;
    }

    return <Authenticator initData={context.initData}/>
}

export default StartParamParser;