import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAxiosRequest, { GET } from '../../hooks/useAxiosRequest';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
    Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

// 1. Helper function to group data by ISO week *and* keep track of the actual items
function groupByIsoWeek(driverInvoices, driverCredits, driverPayments) {
    const grouped = {};

    const addRecord = (weekKey, field, record) => {
        if (!grouped[weekKey]) {
            grouped[weekKey] = {
                week: weekKey,
                debitsSum: 0,
                creditsSum: 0,
                paymentsSum: 0,
                debitsArr: [],
                creditsArr: [],
                paymentsArr: []
            };
        }
        switch (field) {
            case 'invoice':
                grouped[weekKey].debitsSum += parseFloat(record.total || 0);
                grouped[weekKey].debitsArr.push(record);
                break;
            case 'credit':
                grouped[weekKey].creditsSum += parseFloat(record.total || 0);
                grouped[weekKey].creditsArr.push(record);
                break;
            case 'payment':
                grouped[weekKey].paymentsSum += parseFloat(record.total || 0);
                grouped[weekKey].paymentsArr.push(record);
                break;
            default:
                break;
        }
    };

    driverInvoices.forEach(inv => {
        const date = moment(inv.date);
        const weekStr = `${date.isoWeekYear()}-W${String(date.isoWeek()).padStart(2, '0')}`;
        addRecord(weekStr, 'invoice', inv);
    });
    driverCredits.forEach(cred => {
        const date = moment(cred.date);
        const weekStr = `${date.isoWeekYear()}-W${String(date.isoWeek()).padStart(2, '0')}`;
        addRecord(weekStr, 'credit', cred);
    });
    driverPayments.forEach(pay => {
        const date = moment(pay.date);
        const weekStr = `${date.isoWeekYear()}-W${String(date.isoWeek()).padStart(2, '0')}`;
        addRecord(weekStr, 'payment', pay);
    });

    // Convert to array and sort descending by weekKey
    // (String compare will work if your format is YYYY-Wxx because they sort lexically.)
    return Object.values(grouped).sort((a, b) => (b.week > a.week ? 1 : -1));
}

// 2. Reusable component to render a basic list view
const renderTable = (data, columns, context, onClickHandler, title) => (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h6" align="center">
            {title}
        </Typography>
        <List dense={true}>
            {data.map((item, index) => (
                <ListItem
                    button
                    key={index}
                    onClick={() => onClickHandler(item.id, context)}
                    sx={{
                        cursor: 'pointer',
                        border: '1px solid #e0e0e0',
                        borderBottom: '2px solid #e0e0e0',
                        padding: '0px 5px 0px 5px',
                        marginBottom: '10px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        },
                        transition: 'background-color 0.3s, box-shadow 0.3s'
                    }}
                >
                    <Grid container alignItems="center">
                        {columns.map((col, colIndex) => (
                            <Grid item xs={col.width} key={colIndex}>
                                <ListItemText
                                    primary={item[col.key]}
                                    secondary={col.secondary ? item[col.secondary] : null}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ListItem>
            ))}
        </List>
    </Paper>
);

export default function InvoiceComponent() {
    const [data, setData] = useState(null);
    const [weeklyData, setWeeklyData] = useState(null);
    const [loading, setLoading] = useState(false);

    // Existing invoice detail dialog states
    const [context, setContext] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    // Additional states for sub-list dialog when multiple items exist
    const [openWeekDialog, setOpenWeekDialog] = useState(false);
    const [weekItems, setWeekItems] = useState([]);
    const [weekType, setWeekType] = useState(null);

    const selectedBranch = useSelector(state => state.nav.selectedBranch);
    const user = useSelector(state => state.auth.user);
    const axiosRequest = useAxiosRequest();

    // Fetch data
    useEffect(() => {
        setLoading(true);
        const [request, cancelRequest] = axiosRequest(GET, '/driver-api/invoices/invoices', {
            driver_id: user.id,
            branch_code: selectedBranch.code
        });

        request
            .then(response => {
                setData(response);
                // 3. Group data by ISO week as soon as it's loaded
                const grouped = groupByIsoWeek(
                    response.driverInvoices || [],
                    response.driverCredits || [],
                    response.driverPayments || []
                );
                setWeeklyData(grouped);
            })
            .catch(() => {})
            .finally(() => setLoading(false));

        return () => {
            cancelRequest();
        };
    }, [user, selectedBranch, axiosRequest]);

    // Handle selection of an invoice/credit/payment
    const handleInvoiceSelected = (id, thisContext) => {
        setLoading(true);
        setContext(thisContext);

        let dataRecordWithId;
        if (thisContext === 'invoice') {
            dataRecordWithId = data.driverInvoices.find(record => record.id === id);
        } else if (thisContext === 'credit') {
            dataRecordWithId = data.driverCredits.find(record => record.id === id);
        } else if (thisContext === 'payment') {
            dataRecordWithId = data.driverPayments.find(record => record.id === id);
        }

        setSelectedInvoice(dataRecordWithId);

        const [request, cancelRequest] = axiosRequest(GET, '/driver-api/invoices/invoice-detail', {
            id: id,
            type: thisContext
        });

        request
            .then(response => {
                setInvoiceDetails(response);
                setOpenDialog(true);
            })
            .catch(() => {})
            .finally(() => setLoading(false));

        return () => {
            cancelRequest();
        };
    };

    // Used when clicking a cell in the weekly summary (for the sums)
    const handleWeekCellClick = (items, type) => {
        if (!items || items.length === 0) return;
        // If there’s exactly 1 record, open it directly
        if (items.length === 1) {
            handleInvoiceSelected(items[0].id, type);
        } else {
            // Otherwise, show a sub-list of all items for that week + type
            setWeekItems(items);
            setWeekType(type);
            setOpenWeekDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setInvoiceDetails(null);
    };

    // Column definitions for your original list
    const invoiceColumns = [
        { label: 'Date', key: 'invoice_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' }
    ];
    const creditColumns = [
        { label: 'Date', key: 'receipt_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' }
    ];
    const paymentColumns = [
        { label: 'Date', key: 'reference_number', width: 10, secondary: 'date' },
        { label: 'Total', key: 'total', width: 2, align: 'right' }
    ];

    const capitalizeFirstLetter = str => {
        if (!str) return '';
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <Box sx={{ padding: 2 }}>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {/* 4. Render Weekly Totals (if we have grouped data) */}
            {weeklyData && (
                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography variant="h6" align="center" sx={{ marginBottom: 2 }}>
                        Weekly Totals
                    </Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Week</TableCell>
                                    <TableCell align="right">Credits</TableCell>
                                    <TableCell align="right">Debits</TableCell>
                                    <TableCell align="right">Payments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weeklyData.map((row, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{row.week}</TableCell>
                                        {/* Credits Cell */}
                                        <TableCell align="right">
                                            {row.creditsSum !== 0 ? (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => handleWeekCellClick(row.creditsArr, 'credit')}
                                                    underline="hover"
                                                >
                                                    {row.creditsSum.toFixed(2)}
                                                </Link>
                                            ) : (
                                                '0.00'
                                            )}
                                        </TableCell>
                                        {/* Debits Cell */}
                                        <TableCell align="right">
                                            {row.debitsSum !== 0 ? (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => handleWeekCellClick(row.debitsArr, 'invoice')}
                                                    underline="hover"
                                                >
                                                    {row.debitsSum.toFixed(2)}
                                                </Link>
                                            ) : (
                                                '0.00'
                                            )}
                                        </TableCell>
                                        {/* Payments Cell */}
                                        <TableCell align="right">
                                            {row.paymentsSum !== 0 ? (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => handleWeekCellClick(row.paymentsArr, 'payment')}
                                                    underline="hover"
                                                >
                                                    {row.paymentsSum.toFixed(2)}
                                                </Link>
                                            ) : (
                                                '0.00'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}

            {/* 5. Render your existing Debits/Credits/Payments tables */}
            {data && (
                <>
                    {renderTable(data.driverInvoices, invoiceColumns, 'invoice', handleInvoiceSelected, 'Your Debits')}
                    {renderTable(data.driverCredits, creditColumns, 'credit', handleInvoiceSelected, 'Your Credits')}
                    {renderTable(data.driverPayments, paymentColumns, 'payment', handleInvoiceSelected, 'Your Payments')}
                </>
            )}

            {/* Dialog for sub-list if multiple items exist in a single week+type */}
            <Dialog
                open={openWeekDialog}
                onClose={() => setOpenWeekDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {capitalizeFirstLetter(weekType)}s in {weekItems?.length} record(s)
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenWeekDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {weekItems.map(item => (
                            <ListItem
                                button
                                key={item.id}
                                onClick={() => {
                                    setOpenWeekDialog(false);
                                    handleInvoiceSelected(item.id, weekType);
                                }}
                            >
                                <ListItemText
                                    primary={
                                        // For invoice => invoice_number, for credit => receipt_number, etc.
                                        item.invoice_number ?? item.reference_number ?? item.receipt_number
                                    }
                                    secondary={item.date}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>

            {/* Existing dialog showing invoice details */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
                    {capitalizeFirstLetter(context)}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {invoiceDetails && selectedInvoice && (
                        <>
                            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>From:</TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.from_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ paddingBottom: '10px' }}>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>Address:</TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.from_address}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ padding: '10px' }}>&nbsp;</TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>Date:</TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.date}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ paddingBottom: '10px' }}>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>
                                                Invoice Number:
                                            </TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.invoice_number ??
                                                    selectedInvoice.reference_number ??
                                                    selectedInvoice.receipt_number}
                                            </TableCell>
                                        </TableRow>
                                        {selectedInvoice.bank_reference && (
                                            <TableRow sx={{ paddingBottom: '10px' }}>
                                                <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>
                                                    Bank Reference:
                                                </TableCell>
                                                <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                    {selectedInvoice.bank_reference}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow sx={{ padding: '10px' }}>&nbsp;</TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>To:</TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.to_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '0 4px 0 4px' }}>
                                                Address:
                                            </TableCell>
                                            <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                {selectedInvoice.to_address}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#f5f5f5',
                                                    padding: '0 4px 0 4px',
                                                    width: '70%'
                                                }}
                                            >
                                                Item
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#f5f5f5',
                                                    padding: '0 4px 0 4px',
                                                    width: '10%'
                                                }}
                                            >
                                                Qty
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#f5f5f5',
                                                    padding: '0 4px 0 4px',
                                                    textAlign: 'right',
                                                    width: '20%'
                                                }}
                                            >
                                                Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* If it's an invoice */}
                                        {context === 'invoice' &&
                                            invoiceDetails.map(item => (
                                                <TableRow key={item.id}>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        <ListItemText
                                                            primary={item.item}
                                                            secondary={item.description}
                                                            sx={{ padding: '0 4px 0 4px' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        {item.quantity}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>
                                                        {item.period_cost_vat_inc}
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                        {/* If it's a credit */}
                                        {context === 'credit' &&
                                            invoiceDetails.map(item => (
                                                <TableRow key={item.id}>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        <ListItemText
                                                            primary={item.item}
                                                            secondary={item.description}
                                                            sx={{ padding: '0 4px 0 4px' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        {item.quantity}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>
                                                        {item.total}
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                        {/* If it's a payment */}
                                        {context === 'payment' &&
                                            invoiceDetails.map(item => (
                                                <TableRow key={item.id}>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        <ListItemText
                                                            primary={item.item}
                                                            secondary={item.description}
                                                            sx={{ padding: '0 4px 0 4px' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px' }}>
                                                        {item.quantity}
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '0 4px 0 4px', textAlign: 'right' }}>
                                                        {item.total}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow sx={{ paddingTop: '10px' }}>
                                            <TableCell sx={{ padding: '6px' }}></TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '6px' }}>Total</TableCell>
                                            <TableCell
                                                sx={{ padding: '0 4px 0 4px', textAlign: 'right', fontWeight: 'bold' }}
                                            >
                                                {selectedInvoice.total}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
}
